import defaultTheme from '@bluheadless/ui/src/theme/default'

const Dropdown = {
	MuiPopover: {
		styleOverrides: {
			paper: {
				'&.configurable-options': {
					minWidth: '210px !important',
					maxHeight: '200px !important',
					boxShadow: 'unset',
					scrollbarWidth: 'thin',
					scrollbarColor: 'var(--color-brand) var(--color-grey0)', // thumb, track
					width: '100%',
					maxWidth: '100%',
					[defaultTheme.breakpoints.up('md')]: {
						width: 'auto',
						maxWidth: 'initial',
					},
					[defaultTheme.breakpoints.down('md')]: {
						left: '0!important',
					},
					'::-webkit-scrollbar': {
						width: 3,
					},
					//track
					'::-webkit-scrollbar-track': {
						background: 'var(--color-grey0)', // grey.lighter
						borderRadius: 3.5,
					},
					//handle
					'::-webkit-scrollbar-thumb': {
						background: 'var(--color-brand)',
						borderRadius: 3.5,
						width: 3,
					},
					//handle on hover
					'::-webkit-scrollbar-thumb:hover': {
						background: '#373737', // grey.dark
					},
					'& .MuiList-root': {
						padding: '0 var(--size-16) !important',
						borderColor: 'var(--color-grey100)',
						'.MuiMenuItem-root': {
							borderColor: 'var(--color-grey0)',
							padding: 'var(--size-8) 0',
							'&:hover,&.Mui-selected,&.Mui-focusVisible,&.Mui-focus': {
								background: 'unset',
							},
							'span:first-of-type': {
								fontSize: '18px',
								textTransform: 'uppercase',
								fontFamily: 'var(--font-garamond-italic)',
								lineHeight: '1.2',
							},
							'span:last-of-type:not(:first-of-type)': {
								fontSize: '12px',
								fontFamily: 'var(--font-garamond-italic)',
								lineHeight: '1.2',
							},
						},
					},
				},
			},
		},
	},
}
export default Dropdown
