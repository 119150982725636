/**
 * AUTO-GENERATED from generate-icons script 🤖 - DO NOT EDIT!
 */

import { forwardRef } from 'react'
import Icon from '../icon/icon'

const NotificationsIconSvg = forwardRef((props, svgRef) => (
	<svg
		data-name="Livello 1"
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		width={props.width || (props.height && Math.floor(+props.height * 1)) || 24}
		height={props.height || (props.width && Math.floor(+props.width / 1)) || 24}
		fontSize="medium"
		ref={svgRef}
		{...props}
	>
		<path d="M20.5 18.5h-18c-.22 0-.41-.14-.48-.35a.48.48 0 01.19-.56C2.23 17.57 5 15.46 5 8.4c0-1.84.67-3.57 1.89-4.87 1.47-1.57 3.65-2.3 5.7-1.94a.499.499 0 01-.18.98c-1.74-.31-3.54.3-4.79 1.63C6.59 5.3 6 6.82 6 8.38c0 5.13-1.39 7.85-2.34 9.1h15.69c-.77-1.02-1.83-3-2.21-6.45-.03-.27.17-.52.44-.55.27-.03.52.17.55.44.55 5.04 2.64 6.63 2.66 6.65.17.13.25.35.18.56-.07.21-.26.35-.47.35z" />
		<path d="M18.5 8.5C16.57 8.5 15 6.93 15 5s1.57-3.5 3.5-3.5S22 3.07 22 5s-1.57 3.5-3.5 3.5zm0-6a2.5 2.5 0 000 5 2.5 2.5 0 000-5zM11.5 22.5c-.44 0-.87-.12-1.25-.33-.38-.22-.69-.53-.92-.91a.493.493 0 01.18-.68c.24-.14.55-.06.68.18.13.23.32.42.55.55.45.26 1.04.26 1.5 0 .23-.13.42-.32.55-.55.14-.24.45-.32.68-.18.24.14.32.45.18.68a2.51 2.51 0 01-2.17 1.24z" />
	</svg>
))
NotificationsIconSvg.displayName = 'NotificationsIconSvg'

const NotificationsIcon = forwardRef((props, ref) => <Icon component={NotificationsIconSvg} ref={ref} {...props} />)
NotificationsIcon.displayName = 'NotificationsIcon'

NotificationsIcon.defaultProps = {
	...Icon.defaultProps,
}
NotificationsIcon.propTypes = {
	...Icon.propTypes,
}

export default NotificationsIcon
export { NotificationsIconSvg }
