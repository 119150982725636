import { fonts } from '../fonts'
import defaultTheme from '@bluheadless/ui/src/theme/default'

const CssBaseline = {
	MuiCssBaseline: {
		styleOverrides: {
			':root, *::before, *::after': {
				colorScheme: 'light dark',
				// fontsize
				'--size-8': '8px',
				'--size-10': '10px',
				'--size-12': '12px',
				'--size-14': '14px',
				'--size-16': '16px',
				'--size-18': '18px',
				'--size-20': '20px',
				'--size-22': '22px',
				'--size-24': '24px',
				'--size-32': '32px',
				'--size-40': '40px',
				'--letter-spacing': '0.08em',
				// font-family
				'--font-garamond-regular': '"AGaramondPro-Regular"',
				'--font-garamond-italic': '"AGaramondPro-Italic"',
				'--font-garamond-black': '"AGaramondPro-Bold"',
				'--font-garamond-black-italic': '"AGaramondPro-BoldItalic"',
				'--font-base': '"HelveticaNeueLTStd-Lt"',
				'--font-base-bold': '"HelveticaNeueLTStd-Md"',
				// brand
				'--color-brand': '#000000',
				// default
				'--color-primary': '#000000',
				'--color-secondary': '#ffffff',
				// others
				'--color-grey0': '#F7F7F7',
				'--color-grey10': '#2A2A2A',
				'--color-grey20': '#525252',
				'--color-grey30': '#757575',
				'--color-grey40': '#9A9A9A',
				'--color-grey50': '#B8B8B8',
				'--color-grey60': '#CFCFCF',
				'--color-grey70': '#E1E1E1',
				'--color-grey80': '#EEEEEE',
				'--color-grey90': '#F8F8F8',
				'--color-grey100': '#F2F2F2',
				'--color-grey110': '#F2F1EF',
				'--color-grey120': '#A0A0A0',
				'--color-grey130': '#373737',
				'--color-brown': '#704214',
				'--color-green': '#008000',
				'--color-blue': '#0D31AB',
				'--color-purple': '#800080',
				'--color-pink': '#FFB6C1',
				'--color-red': '#D13131',
				'--color-orange': '#F28413',
				'--color-yellow': '#FFE400',
				'--color-white': '#FFFFFF',
				'--color-black': '#000000',
				// buttons
				'--button-letter-spacing': '0.12em',
				'--button-primary-size': '14px',
				'--button-primary-color': 'var(--color-secondary)',
				'--button-primary-bgcolor': 'var(--color-primary)',
				'--button-primary-hover-bgcolor': 'var(--color-grey10)',
				'--button-secondary-size': '14px',
				'--button-secondary-color': 'var(--color-primary)',
				'--button-secondary-bgcolor': 'var(--color-secondary)',
				// animations
				'--transition': '.3s cubic-bezier(0.4, 0, 0.2, 1) 0s',
				'--swiper-theme-color': 'var(--color-primary)',
			},
			html: {
				...fonts.helveticaNeueLTStd,

				scrollbarWidth: 'thin',
				scrollbarColor: 'var(--color-brand) var(--color-grey0)',

				'&::-webkit-scrollbar': {
					width: '7px',
					height: '5px',
				},

				'&::-webkit-scrollbar-track': {
					background: 'var(--color-grey0)',
					borderRadius: '3.5px',
				},

				'&::-webkit-scrollbar-thumb': {
					background: 'var(--color-brand)',
					borderRadius: '3.5px',
				},

				'&::-webkit-scrollbar-thumb:hover': {
					background: 'var(--color-grey130)',
				},
			},
			body: {
				'--global-scrollbar-width': '10px',
				...fonts.helveticaNeueLTStd,
				overflowX: 'hidden',
				textTransform: 'lowercase',
				color: 'var(--color-primary)',

				'input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active':
					{
						WebkitBoxShadow: '0 0 0 30px white inset !important',
						WebkitTextFillColor: 'var(--color-primary) !important',
					},

				'& .d-none-desktop': {
					[defaultTheme.breakpoints.up('md')]: {
						display: 'none !important',
					},
				},

				'& .cart-page': {
					'> .MuiContainer-root.main-layout-container': {
						padding: '0 var(--size-20)',
						[defaultTheme.breakpoints.up('md')]: {
							padding: '0 var(--size-14)',
						},
					},
				},

				'& .MuiContainer-root.main-layout-container': {
					marginTop: 0,
				},

				'& .main-layout.guest-order-page': {
					'& .main-layout-container': {
						background: 'var(--color-grey0)',
					},
				},

				// Klarna global styles
				'.KlarnaPlacement-root': {
					'klarna-placement ::part(osm-container)': {
						padding: 0,
					},
					'klarna-placement ::part(osm-message)': {
						textTransform: 'lowercase',
						fontFamily: 'var(--font-base)',
						letterSpacing: 'var(--letter-spacing)',
					},
					'klarna-placement ::part(osm-logo)': {
						textTransform: 'initial',
					},
					'klarna-placement ::part(osm-cta)': {
						display: 'none',
					},
					'klarna-placement #test-badge-element-id': {
						display: 'none !important',
					},
				},
			},
			hr: {
				border: 'none',
				borderTop: '1px solid var(--color-grey60)',
			},
		},
	},
}

export default CssBaseline
