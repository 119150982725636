const BHChangeCountryLanguageTrigger = {
	// country code, language and flag cannot be shown at the same time.
	BHChangeCountryLanguageTrigger: {
		defaultProps: {
			showArrowDown: true,
			showCountryCode: false,
			showCurrencySymbol: true,
			showFlag: false,
			showLanguage: true,
			showSeparator: false,
		},
	},
}
export default BHChangeCountryLanguageTrigger
