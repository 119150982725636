/**
 * AUTO-GENERATED from generate-icons script 🤖 - DO NOT EDIT!
 */

import { forwardRef } from 'react'
import Icon from '../icon/icon'

const SearchIconSvg = forwardRef((props, svgRef) => (
	<svg
		data-name="Livello 2"
		viewBox="0 0 24 24"
		width={props.width || (props.height && Math.floor(+props.height * 1)) || 24}
		height={props.height || (props.width && Math.floor(+props.width / 1)) || 24}
		xmlns="http://www.w3.org/2000/svg"
		fontSize="medium"
		ref={svgRef}
		{...props}
	>
		<g data-name="Livello 1">
			<path d="M20.048 20.5a.42.42 0 01-.313-.134l-3.579-3.58a.443.443 0 010-.634.443.443 0 01.635 0l3.58 3.579a.443.443 0 010 .635.45.45 0 01-.314.134z" />
			<path d="M11.1 18.71a7.61 7.61 0 01-7.604-7.605A7.61 7.61 0 0111.1 3.5a7.61 7.61 0 017.605 7.605 7.538 7.538 0 01-2.219 5.369 7.56 7.56 0 01-5.386 2.237zm0-14.315a6.713 6.713 0 00-6.71 6.71 6.713 6.713 0 006.71 6.71c1.8 0 3.481-.697 4.752-1.968A6.713 6.713 0 0011.1 4.394z" />
		</g>
	</svg>
))
SearchIconSvg.displayName = 'SearchIconSvg'

const SearchIcon = forwardRef((props, ref) => <Icon component={SearchIconSvg} ref={ref} {...props} />)
SearchIcon.displayName = 'SearchIcon'

SearchIcon.defaultProps = {
	...Icon.defaultProps,
}
SearchIcon.propTypes = {
	...Icon.propTypes,
}

export default SearchIcon
export { SearchIconSvg }
