const Input = {
	MuiFormControl: {
		defaultProps: {
			margin: 'none',
		},
		styleOverrides: {
			root: {
				'& .MuiSelect-icon.MuiSvgIcon-root': {
					top: '5px',
					right: '0',
				},
				'& .MuiInputBase-root .MuiSelect-select': {
					padding: '6px 0',
				},
				'& .phone-input': {
					padding: '11px 0 0',
				},
			},
		},
	},
	MuiInputLabel: {
		styleOverrides: {
			root: {
				position: 'absolute',
				left: 0,
				top: 0,
				transform: 'translate(0, 20px) scale(1)',
				transformOrigin: 'top left',
				overflow: 'hidden',
				textOverflow: 'ellipsis',
				maxWidth: '100%',
				padding: '0',
				color: 'var(--color-primary)',
				'&.Mui-focused,&.MuiFormLabel-filled': {
					transform: 'translate(0, -1.5px) scale(0.75)',
				},
			},
		},
	},
	MuiSelect: {
		defaultProps: {
			variant: 'standard',
		},
	},
	MuiOutlinedInput: {
		styleOverrides: {
			root: {
				padding: '24px 0 26px',
			},
			input: {
				padding: '21px 24px',
				height: '18px',
			},
			notchedOutline: {
				border: '0px',
			},
			multiline: {
				border: '0px',
				borderRadius: '0px',
				borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
				padding: '18px 0px !important',
			},
		},
	},
	MuiInputAdornment: {
		styleOverrides: {
			root: {
				paddingRight: '10px',
				paddingBottom: '4px',
			},
		},
	},
	MuiInputBase: {
		styleOverrides: {
			root: {
				'&.MuiInput-root': {
					'&:before,&:after,&:hover:before': {
						borderBottom: '1px solid var(--color-primary) !important',
					},
				},
				'&.MuiInputBase-adornedStart': {
					paddingLeft: '0px',
				},
				'&.MuiInputBase-adornedEnd': {
					paddingRight: '0px',
				},
			},
			input: {
				fontSize: 'var(--size-12)',
			},
		},
	},
	MuiFormHelperText: {
		styleOverrides: {
			root: {
				marginTop: '0px',
				marginBottom: '4px',
			},
		},
	},
}

export default Input
