const BHQuickBuy = {
	BHQuickBuy: {
		defaultProps: {
			showButtonClose: true,
			variant: 'lg',
			PaperProps: {
				sx: {
					alignContent: 'flex-start',
					overflowScrolling: 'touch',
					WebkitOverflowScrolling: 'touch',
					padding: 0,
					maxWidth: '1060px',
				},
			},
		},
	},
}
export default BHQuickBuy
