const List = {
	MuiMenu: {
		styleOverrides: {
			list: {
				borderRadius: 0,
				border: '1px solid var(--color-black)',
				marginTop: 0,
				padding: 0,
			},
		},
	},
}

export default List
