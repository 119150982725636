const Table = {
	MuiTableCell: {
		styleOverrides: {
			body: {
				color: 'var(--color-primary)',
				fontSize: '12px',
			},
			root: {
				fontSize: '12px',
			},
		},
	},
}

export default Table
