/**
 * AUTO-GENERATED from generate-icons script 🤖 - DO NOT EDIT!
 */

import { forwardRef } from 'react'
import Icon from '../icon/icon'

const XIconSvg = forwardRef((props, svgRef) => (
	<svg
		data-name="Livello 1"
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		width={props.width || (props.height && Math.floor(+props.height * 1)) || 24}
		height={props.height || (props.width && Math.floor(+props.width / 1)) || 24}
		fontSize="medium"
		ref={svgRef}
		{...props}
	>
		<path d="M19.5 20a.47.47 0 01-.35-.15L12 12.7l-7.15 7.15c-.2.2-.51.2-.71 0s-.2-.51 0-.71l7.15-7.15-7.15-7.15c-.2-.2-.2-.51 0-.71s.51-.2.71 0L12 11.28l7.15-7.15c.2-.2.51-.2.71 0s.2.51 0 .71l-7.15 7.15 7.15 7.15c.2.2.2.51 0 .71-.1.1-.23.15-.35.15z" />
	</svg>
))
XIconSvg.displayName = 'XIconSvg'

const XIcon = forwardRef((props, ref) => <Icon component={XIconSvg} ref={ref} {...props} />)
XIcon.displayName = 'XIcon'

XIcon.defaultProps = {
	...Icon.defaultProps,
}
XIcon.propTypes = {
	...Icon.propTypes,
}

export default XIcon
export { XIconSvg }
