const commonStyles = {
	padding: '4px 2px 5px',
	minWidth: 'unset',
	minHeight: '40px',
	borderWidth: '0',
	borderRadius: '0px',
	lineHeight: 'var(--size-10)',
	position: 'relative',
	overflow: 'hidden',
	background: 'none',
	'&:hover': {
		'&::after': {
			transform: 'translateY(0)',
		},
	},
	'&:focus': {
		'&::after': {
			transform: 'translateY(0)',
		},
	},
	'&:active': {
		'&::after': {
			transform: 'translateY(0)',
		},
	},
	'&.Mui-disabled': {
		color: 'var(--color-grey30)',
	},
}

const Banners = {
	MuiContainer: {
		styleOverrides: {
			root: {
				'&.Magento-PageBuilder-Row': {
					overflow: 'visible', // this is necessary for child sticky element
					'& .Magento-PageBuilder-Column': {
						overflow: 'visible', // this is necessary for child sticky element
						'&.column-50': {
							flexBasis: '50%',
						},
					},
				},
			},
		},
	},
	MuiGrid: {
		styleOverrides: {
			root: {
				'& .overlay-container': {
					'& .MuiButton-containedPrimary': {
						...commonStyles,
						color: 'var(--color-primary)',
						'&::after': {
							content: `""`,
							display: 'block',
							position: 'absolute',
							left: 0,
							right: 0,
							top: 0,
							bottom: 0,
							zIndex: '-1',
							background: 'var(--color-primary)',
							transform: 'translateY(calc(100% - 1px))',
							transition: 'all var(--transition)',
						},
						'&:hover,&:focus,&:active': {
							color: 'var(--color-secondary)',
						},
					},
					'& .MuiButton-containedSecondary': {
						...commonStyles,
						color: 'var(--color-secondary)',
						'&::after': {
							content: `""`,
							display: 'block',
							position: 'absolute',
							left: 0,
							right: 0,
							top: 0,
							bottom: 0,
							zIndex: '-1',
							background: 'var(--color-secondary)',
							transform: 'translateY(calc(100% - 1px))',
							transition: 'all var(--transition)',
						},
						'&:hover,&:focus,&:active': {
							color: 'var(--color-primary)',
						},
					},
					'& .MuiButton-textPrimary': {
						color: 'var(--color-secondary)',
						'&:hover,&:focus,&:active': {
							color: 'var(--color-primary)',
						},
						'&::after': {
							backgroundColor: 'var(--color-secondary)',
						},
					},
				},
			},
		},
	},
}

export default Banners
