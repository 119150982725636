import { defaultProps as MenuDefaultProps } from '@bluheadless/ui/src/molecules/menu/menu-props'

const BHHeader = {
	BHHeader: {
		defaultProps: {
			menuProps: {
				...MenuDefaultProps,
				dropDownProps: {
					placement: 'bottom-start',
					style: { width: '100%' },
					popperOptions: {
						strategy: 'absolute',
					},
				},
			},
		},
	},
}
export default BHHeader
