const Accordion = {
	MuiAccordionDetails: {
		styleOverrides: {
			root: {
				color: 'var(--color-primary)',
				paddingTop: 0,
				paddingBottom: 33,
			},
		},
	},
}

export default Accordion
