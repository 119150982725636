import { fonts } from '../fonts'

const BHNewsletter = {
	BHNewsletter: {
		defaultProps: {
			showButtonClose: true,
			variant: 'xl',
			PaperProps: {
				sx: {
					padding: 0,
					alignContent: 'flex-start',
					overflowScrolling: 'touch',
					WebkitOverflowScrolling: 'touch',
					maxWidth: '1105px',
					borderRadius: '0px',
				},
			},
			title: {
				color: 'primary',
				align: 'center',
				component: 'p',
				sx: {
					...fonts.neuzeitGro,
					letterSpacing: '0',
				},
			},
			description: {
				variant: 'body1',
				color: 'grey.main',
				align: 'center',
				sx: {
					fontSize: 'var(--size-10)',
					lineHeight: '16px',
				},
			},
			checkboxProps: {
				labelProps: {
					sx: { alignItems: 'flex-start', marginTop: '24px' },
				},
			},
			buttonProps: {
				color: 'primary',
			},
		},
	},
}
export default BHNewsletter
