const Dialog = {
	MuiDialog: {
		styleOverrides: {
			root: {
				'&.SizeGuide-root': {
					'& .MuiPaper-root': {
						minHeight: 'initial',
					},
				},
			},
		},
	},
}

export default Dialog
